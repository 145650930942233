import toast from "react-hot-toast"
import { premiumEndpoints, salesEndpoint } from "../api"
import { apiConnector } from "../apiConnector"
import { setAllTimeSalesStats, setPartnersSales, setPayPalGstSheets, setRazorPayGstSheets, setSales, setStatewiseSales, setTotalPartnerCourseSales } from "../../slices/premiumSlice"

const { GET_PREMIUM_GST_SHEET, GET_PREMIUM_GST_SHEET_PAYPAL } = premiumEndpoints
const { GET_ALL_TIME_SALES_STATS, SALES_IN_STATE, TOTAL_PARTNER_SALES, TOTAL_SALES, TOTAL_PARTNER_COURSE_SALES } = salesEndpoint

export function getAllRazorPayGSTSheet() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_PREMIUM_GST_SHEET)

            if (response.data.success) {
                dispatch(setRazorPayGstSheets(response.data.result))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getAllPayPalGSTSheet() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_PREMIUM_GST_SHEET_PAYPAL)

            if (response.data.success) {
                dispatch(setPayPalGstSheets(response.data.result))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getAllTimeSalesStats() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_TIME_SALES_STATS)

            if (response.data.success) {
                dispatch(setAllTimeSalesStats(response.data.sales[0]))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function getSalesInState(start_date, end_date) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", SALES_IN_STATE + `?start_date=${start_date}&end_date=${end_date}`)

            if (response.data.success) {
                dispatch(setStatewiseSales(response.data.sales))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function getPartnerSales(page, partner, start_date, end_date) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", TOTAL_PARTNER_SALES + `${page}/${partner}?start_date=${start_date}&end_date=${end_date}`)

            if (response.data.success) {
                dispatch(setPartnersSales({ totals: response.data.totals.inr, daywises: response.data.daywiseSales, dayswiseUSD: response.data.daywiseSalesUSD }))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
export function getSales(page, start_date, end_date) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", TOTAL_SALES + `${page}?start_date=${start_date}&end_date=${end_date}`)

            if (response.data.success) {
                dispatch(setSales({ totals: response.data.totals.inr, daywises: response.data.daywiseSalesINR, daywisesUSD: response.data.daywiseSalesUSD}))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}

export function getPartnerCourseSales(page, start_date, end_date) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", TOTAL_PARTNER_COURSE_SALES + `${page}?start_date=${start_date}&end_date=${end_date}`)
            if (response.data.success) {
                dispatch(setTotalPartnerCourseSales({ totals: response.data.totals.inr, daywises: response.data.daywiseSalesINR, daywisesUSD: response.data.daywiseSalesUSD}))
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}
