import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    razorPayGstSheets: [],
    payPalGstSheets: [],
    allTimeSalesStats: [],
    statewiseSales: [],
    partnersSales: {},
    totalSales: {},
    totalSalesUSD: {},
    totalPartnerCourseSales: {},
};

const premiumSlice = createSlice({
    name: "premium",
    initialState: initialState,
    reducers: {
        setRazorPayGstSheets(state, value) {
            state.razorPayGstSheets = value.payload;
        },
        setPayPalGstSheets(state, value) {
            state.payPalGstSheets = value.payload;
        },
        setAllTimeSalesStats(state, value) {
            state.allTimeSalesStats = value.payload;
        },
        setStatewiseSales(state, value) {
            state.statewiseSales = value.payload;
        },
        setPartnersSales(state, value) {
            state.partnersSales = value.payload;
        },
        setSales(state, value) {
            state.totalSales = value.payload;
        },
        setTotalPartnerCourseSales(state, value) {
            state.totalPartnerCourseSales = value.payload;
        },
    },
});

export const { setRazorPayGstSheets, setPayPalGstSheets, setAllTimeSalesStats, setStatewiseSales, setPartnersSales, setSales, setSalesUSD, setTotalPartnerCourseSales } = premiumSlice.actions;

export default premiumSlice.reducer;
