import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { getPartnerCourseSales } from '../../services/operations/premium';
import { adjustToUTC, calculateTotalsForINR, calculateTotalsForUSD, formatCurrency, getISTDateTime } from '../../utils/Utils';
import PartnersAllTimeSale from '../../components/SalesTracker/PartnersAllTimeSale';
import LineChart from '../../components/Charts/LineChart';

const PartnersCourseSale = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [startDate, setStartDate] = useState("2024-08-07");
    const [endDate, setEndDate] = useState(getISTDateTime().split(' ')[0]);

    const { totalPartnerCourseSales } = useSelector((state) => state.premium)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPartnerCourseSales(1, adjustToUTC(startDate, false), adjustToUTC(endDate.split(' ')[0], true)))
    }, [dispatch, startDate, endDate])

    let chartData
    if (totalPartnerCourseSales && totalPartnerCourseSales?.daywisesUSD) {

        chartData = {
            labels: totalPartnerCourseSales?.daywisesUSD?.map(item => {
                const utcDate = new Date(item.sale_date);

                // Convert UTC to IST (UTC+5:30)
                const istDate = new Date(utcDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));

                const day = String(istDate.getDate()).padStart(2, '0');
                const month = String(istDate.getMonth() + 1).padStart(2, '0');
                const year = istDate.getFullYear();

                return `${day}-${month}-${year}`;
            }),
            datasets: [
                // Indigo line
                {
                    data: totalPartnerCourseSales?.daywisesUSD?.map(item => item.total_amount),
                    fill: true,
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: '#0891b2',
                    clip: 20,
                },
                // Gray line
                {
                    data: totalPartnerCourseSales?.daywisesUSD?.map(item => item.total_sales),
                    borderWidth: 2,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointBackgroundColor: '#ea580c',
                    clip: 20,
                },

            ],
        };
    }

    const usdStats = calculateTotalsForUSD(totalPartnerCourseSales && totalPartnerCourseSales?.daywisesUSD, 83.99)
    const inrStats = calculateTotalsForINR(totalPartnerCourseSales && totalPartnerCourseSales?.daywises)
    // console.log(partnerUSDStats)
    return (
        <div className="flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa] dark:text-white">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex justify-between isolate'>
                            <span className='text-2xl font-bold'>Partner Course Sales INR</span>

                            <div className="flex items-center space-x-4">
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={endDate.split(' ')[0]}
                                    onChange={(e) => {
                                        const selectedDate = new Date(e.target.value);
                                        setEndDate(selectedDate.toISOString().slice(0, 10) + ' ' + new Date().toISOString().slice(11, 19));
                                    }}
                                />
                            </div>
                        </div>
                        <div className='mt-8 bg-dark_50 mb-8 flex justify-center items-center rounded-lg p-4 w-full'>
                            <div className='flex  space-x-8'>
                                <p className="text-xl  text-gray-600 dark:text-gray-400">
                                    Total Users: {inrStats.totalUsers}
                                </p>
                                <p className="text-xl  text-gray-600 dark:text-gray-400">
                                    Total Revenue: {formatCurrency(inrStats.totalRevenue)}
                                </p>
                                <p className="text-xl  text-gray-600 dark:text-gray-400">
                                    Total GST: {formatCurrency(inrStats.gstAmount)}
                                </p>
                                <p className="text-xl  text-gray-600 dark:text-gray-400">
                                    Total Profit: {formatCurrency(inrStats.totalProfit)}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col col-span-full sm:col-span-6 xl:col-span-4 w-full mt-10 bg-white dark:bg-dark_50 p-4 rounded-lg'>
                            <PartnersAllTimeSale data={totalPartnerCourseSales && totalPartnerCourseSales?.daywises} />
                        </div>
                        <div className='flex justify-between isolate mt-20'>
                            <span className='text-2xl font-bold'>Partner Course Sales IN USD</span>

                            <div className="flex items-center space-x-4">
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <input
                                    type="date"
                                    className="border border-gray-300 p-2 dark:text-white dark:bg-dark_40 dark:border-zinc-800 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    value={endDate.split(' ')[0]}
                                    onChange={(e) => {
                                        const selectedDate = new Date(e.target.value);
                                        setEndDate(selectedDate.toISOString().slice(0, 10) + ' ' + new Date().toISOString().slice(11, 19));
                                    }}
                                />
                            </div>
                        </div>
                        <div className='mt-8 bg-dark_50 mb-8 flex justify-center items-center rounded-lg p-4 w-full'>
                            <div className='flex  space-x-8'>
                                <p className="text-xl  text-gray-600 dark:text-gray-400">
                                    Total Users: {usdStats.totalUsers}
                                </p>
                                <p className="text-xl  text-gray-600 dark:text-gray-400">
                                    Total Profit in USD: ${usdStats.totalProfit}
                                </p>
                                <p className="text-xl  text-gray-600 dark:text-gray-400">
                                    Total Profit in INR: {formatCurrency(usdStats.totalProfitINR)}
                                </p>
                            </div>
                        </div>
                        <div className="flex-grow w-full h-full mt-4 dark:bg-dark_40">
                            <LineChart currency={"USD"} width={800} data={chartData} />
                        </div>
                    </div>

                </main>
            </div>
        </div>
    )
}


export default PartnersCourseSale